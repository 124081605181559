import React from 'react';
import { useBundle } from '@amzn/react-arb-tools';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from '../constants';
import { t } from 'src/helpers';
import { Button } from '@amzn/alchemy-components-react';

const ButtonDemo = () => {
    const [bundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);

    return (
        <div>
            <Button
                className="mr-1"
                variant="primary"
                label={t(bundle, 'primary_button_label')}
                onClick={() => alert('You clicked the primary button!')}
            />
            <Button
                variant="secondary"
                label={t(bundle, 'secondary_button_label')}
                onClick={() => alert('You clicked the secondary button!')}
            />
        </div>
    );
};

export default ButtonDemo;
