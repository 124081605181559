import KatalLogger, { Level } from '@amzn/katal-logger';

const katalLoggerConfig = {
    url: 'https://g3v356sevl.execute-api.us-west-2.amazonaws.com/prod/v1/log',
    logThreshold: Level.INFO,
    maxLogLineSize: 10000,
    logToConsole: 'Alpha'.toLowerCase() !== 'prod',
    context: {
        'User-Agent': window.navigator.userAgent,
    },
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();
