import LanguagePicker from 'src/components/LanguagePicker';
import ButtonDemo from 'src/components/ButtonDemo';
import { Card } from '@amzn/alchemy-components-react';

export const DemoPage = () => {
    return (
        <div id="demo-page" data-testid="test-demo-page" className="container-fluid">
            <div className="row">
                <div className="col d-flex justify-content-center">
                    <Card>
                        <LanguagePicker />
                        <ButtonDemo />
                    </Card>
                </div>
            </div>
        </div>
    );
};
