import { TopBar } from '@amzn/alchemy-components-react';
import { Outlet } from 'react-router-dom';

export const RootPage = () => {
    const rootLink = {
        text: 'Tool Menu',
        url: '/',
    };

    return (
        <div id="root-page" data-testid="test-root-page">
            <TopBar header={rootLink} />
            <br />
            <main>
                <Outlet />
            </main>
        </div>
    );
};
