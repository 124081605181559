import React, {
	forwardRef,
	useEffect,
	useRef,
	useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { filterProps } from '../utils';
import '@amzn/alchemy-components/dist/RadioButtons/index.js';

const RadioButtons = forwardRef((props, ref) => {
	const internalRef = useRef(null);
	const { children, className, style, onChange, onInput, ...rest } = props;
	const { attributes, data, events } = filterProps(rest);

	useImperativeHandle(ref, () => internalRef.current);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			Object.assign(internalRef.current, data);
		}
	}, [data]);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			const elRef = internalRef.current;
			const scopedEvents = events;

			elRef.addEventListener('change', onChange);
			elRef.addEventListener('input', onInput);
			scopedEvents.forEach((ev) => {
				elRef.addEventListener(ev.key, ev.value);
			});

			return () => {
				elRef.removeEventListener('change', onChange);
				elRef.removeEventListener('input', onInput);
				scopedEvents.forEach((ev) => {
					elRef.removeEventListener(ev.key, ev.value);
				});
			};
		}
	}, [data, events]);

	return (
		<alchemy-radiobuttons
			class={className}
			ref={internalRef}
			style={style}
			{...attributes}
		>
			{children}
		</alchemy-radiobuttons>
	);
});

RadioButtons.displayName = 'RadioButtons';

RadioButtons.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	style: PropTypes.any,
	onChange: PropTypes.func,
	onInput: PropTypes.func,
};

export default RadioButtons;
