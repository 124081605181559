import React, {
	forwardRef,
	useEffect,
	useRef,
	useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { filterProps } from '../utils';
import '@amzn/alchemy-components/dist/Spinner/index.js';

const Spinner = forwardRef((props, ref) => {
	const internalRef = useRef(null);
	const { className, style, ...rest } = props;
	const { attributes, data, events } = filterProps(rest);

	useImperativeHandle(ref, () => internalRef.current);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			Object.assign(internalRef.current, data);
		}
	}, [data]);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			const elRef = internalRef.current;
			const scopedEvents = events;

			scopedEvents.forEach((ev) => {
				elRef.addEventListener(ev.key, ev.value);
			});

			return () => {
				scopedEvents.forEach((ev) => {
					elRef.removeEventListener(ev.key, ev.value);
				});
			};
		}
	}, [data, events]);

	return (
		<alchemy-spinner
			class={className}
			style={style}
			ref={internalRef}
			{...attributes}
		></alchemy-spinner>
	);
});

Spinner.displayName = 'Spinner';

Spinner.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	style: PropTypes.any,
};

export default Spinner;
