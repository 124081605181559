import React from 'react';
import { MbmProvider } from '@amzn/react-arb-tools';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { DEFAULT_LOCALE } from '../constants';
import arbManifest from 'translations/arbManifest';
import { resolveBundleUrlWithFallbacks } from '../helpers';
import { logger } from 'src/logger';
import initialMetricsPublisher from 'src/metrics';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ErrorPage } from 'src/pages/ErrorPage';
import { DemoPage } from 'src/pages/DemoPage';
import { RootPage } from 'src/pages/RootPage';
import { WelcomePage } from 'src/pages/WelcomePage';

const mbmOptions = {
    arbManifest,
    defaultLocalizationContext: new LocalizationContextBuilder()
        .withLocale(DEFAULT_LOCALE)
        .withDefaultLocale(DEFAULT_LOCALE)
        .build(),
    resolveBundleUrl: (resourcePackPath: string | undefined, locale: string, bundle: string) =>
        resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
};

const actionMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('AppComponent');

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootPage />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <WelcomePage />,
            },
            {
                path: 'demo',
                element: <DemoPage />,
            },
        ],
    },
]);

const App = () => {
    logger.info('Rendering the App component.');
    actionMetricsPublisher.publishCounter('RenderCount', 1);

    return (
        <MbmProvider {...mbmOptions}>
            <RouterProvider router={router} />
        </MbmProvider>
    );
};

export default App;
