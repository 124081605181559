import * as KatalMetrics from '@amzn/katal-metrics';
import KatalMetricsDriverConsoleLogJson from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverConsoleLogJson';
import KatalMetricsDriverArrayCollector from '@amzn/katal-metrics/lib/driver/KatalMetricsDriverArrayCollector';
import { KatalMonitoringDriver, KatalMonitoringDriverOptions } from '@amzn/katal-monitoring-aws-driver';

// Please see the following guide for questions related to the config below:
// https://katal.amazon.dev/metrics/getting-started-guide/

const metricsConsoleErrorHandler = (err: Error) => console.error(err);

const makeMetricsDriver = (): KatalMetrics.MetricsDriver => {
    if (process.env.NODE_ENV === 'test') {
        const metricsDriver = new KatalMetricsDriverArrayCollector();
        //  Attach to global window object so tests can see it
        (window as any).metricsDriver = metricsDriver;
        return metricsDriver;
    } else if (process.env.NODE_ENV !== 'production') {
        return new KatalMetricsDriverConsoleLogJson();
    } else {
        const monitoringConfig: KatalMonitoringDriverOptions = {
            url: 'https://fnc7e583x4.execute-api.us-west-2.amazonaws.com/prod/v1/monitoring',
            errorHandler: metricsConsoleErrorHandler,
        };
        return new KatalMonitoringDriver(monitoringConfig);
    }
};

const makePublisher = (): KatalMetrics.Publisher => {
    const metricsDriver = makeMetricsDriver();
    const initialMetricsContext = new KatalMetrics.Context.Builder()
        .withSite('ToolMenu')
        .withServiceName('ToolMenuWebsite')
        .withCloudWatchDimensions([
            new KatalMetrics.Metric.String('stage', 'Alpha'),
            new KatalMetrics.Metric.String('region', 'us-west-2'),
        ])
        .build();
    return new KatalMetrics.Publisher(metricsDriver, metricsConsoleErrorHandler, initialMetricsContext);
};

const initialMetricsPublisher = makePublisher();

export default initialMetricsPublisher;
