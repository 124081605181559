import React, {
	forwardRef,
	useEffect,
	useRef,
	useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { filterProps } from '../utils';
import '@amzn/alchemy-components/dist/Autocomplete/index.js';

const Autocomplete = forwardRef((props, ref) => {
	const internalRef = useRef(null);
	const {
		className,
		onChange,
		style,
		onInput,
		onOpen,
		onClose,
		onScroll,
		onKeyDown,
		onKeyUp,
		...rest
	} = props;
	const { attributes, data, events } = filterProps(rest);

	useImperativeHandle(ref, () => internalRef.current);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			Object.assign(internalRef.current, data);
		}
	}, [data]);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			const elRef = internalRef.current;
			const scopedEvents = events;

			elRef.addEventListener('change', onChange);
			elRef.addEventListener('input', onInput);
			elRef.addEventListener('open', onOpen);
			elRef.addEventListener('close', onClose);
			elRef.addEventListener('scroll', onScroll);
			// react calls the keydown event as the keyDown event so the event is not being caught
			elRef.addEventListener('keydown', onKeyDown);
			elRef.addEventListener('keyup', onKeyUp);

			scopedEvents.forEach((ev) => {
				elRef.addEventListener(ev.key, ev.value);
			});

			return () => {
				elRef.removeEventListener('input', onInput);
				elRef.removeEventListener('change', onChange);
				elRef.removeEventListener('open', onOpen);
				elRef.removeEventListener('close', onClose);
				elRef.removeEventListener('scroll', onScroll);
				elRef.removeEventListener('keydown', onKeyDown);
				elRef.removeEventListener('keyup', onKeyUp);

				scopedEvents.forEach((ev) => {
					elRef.removeEventListener(ev.key, ev.value);
				});
			};
		}
	}, [data, events]);

	return (
		<alchemy-autocomplete
			class={className}
			ref={internalRef}
			style={style}
			{...attributes}
		></alchemy-autocomplete>
	);
});

Autocomplete.displayName = 'Autocomplete';

Autocomplete.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	style: PropTypes.any,
	options: PropTypes.array,
	onChange: PropTypes.func,
	onInput: PropTypes.func,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	onScroll: PropTypes.func,
	onKeyDown: PropTypes.func,
	onKeyUp: PropTypes.func,
};

export default Autocomplete;
