import React, {
	forwardRef,
	useEffect,
	useRef,
	useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { filterProps } from '../utils';
import '@amzn/alchemy-components/dist/Textarea/index.js';

const Textarea = forwardRef((props, ref) => {
	const internalRef = useRef(null);
	const {
		children,
		className,
		style,
		onInput,
		onChange,
		onKeyDown,
		onKeyUp,
		...rest
	} = props;
	const { attributes, data, events } = filterProps(rest);

	useImperativeHandle(ref, () => internalRef.current);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			Object.assign(internalRef.current, data);
		}
	}, [data]);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			const elRef = internalRef.current;
			const scopedEvents = events;

			elRef.addEventListener('input', onInput);
			elRef.addEventListener('change', onChange);
			// react calls the keydown event as the keyDown event so the event is not being caught
			elRef.addEventListener('keydown', onKeyDown);
			elRef.addEventListener('keyup', onKeyUp);

			scopedEvents.forEach((ev) => {
				elRef.addEventListener(ev.key, ev.value);
			});

			return () => {
				elRef.removeEventListener('input', onInput);
				elRef.removeEventListener('change', onChange);
				elRef.removeEventListener('keydown', onKeyDown);
				elRef.removeEventListener('keyup', onKeyUp);

				scopedEvents.forEach((ev) => {
					elRef.removeEventListener(ev.key, ev.value);
				});
			};
		}
	}, [data, events]);

	return (
		<alchemy-textarea
			class={className}
			ref={internalRef}
			style={style}
			{...attributes}
		>
			{children}
		</alchemy-textarea>
	);
});

Textarea.displayName = 'Textarea';

Textarea.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	style: PropTypes.any,
	onInput: PropTypes.func,
	onChange: PropTypes.func,
	onKeyDown: PropTypes.func,
	onKeyUp: PropTypes.func,
};

export default Textarea;
