import React, {
	forwardRef,
	useEffect,
	useRef,
	useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { filterProps } from '../utils';
import '@amzn/alchemy-components/dist/Tag/index.js';

const Tag = forwardRef((props, ref) => {
	const internalRef = useRef(null);
	const { children, className, style, onDismiss, ...rest } = props;
	const { attributes, data, events } = filterProps(rest);

	useImperativeHandle(ref, () => internalRef.current);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			Object.assign(internalRef.current, data);
		}
	}, [data]);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			const elRef = internalRef.current;
			const scopedEvents = events;

			elRef.addEventListener('dismiss', onDismiss);

			scopedEvents.forEach((ev) => {
				elRef.addEventListener(ev.key, ev.value);
			});

			return () => {
				elRef.removeEventListener('dismiss', onDismiss);

				scopedEvents.forEach((ev) => {
					elRef.removeEventListener(ev.key, ev.value);
				});
			};
		}
	}, [data, events]);

	return (
		<alchemy-tag
			class={className}
			style={style}
			ref={internalRef}
			{...attributes}
		>
			{children}
		</alchemy-tag>
	);
});

Tag.displayName = 'Tag';

Tag.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	style: PropTypes.any,
	onDismiss: PropTypes.func,
};

export default Tag;
