import { useRouteError } from 'react-router-dom';
import { logger } from 'src/logger';

type RouteError = {
    status: number;
    statusText: string;
    data: string;
};

export const ErrorPage = () => {
    const error = useRouteError() as RouteError;
    logger.error('Unexpected route error!', error);

    return (
        <div id="error-page" data-testid="test-error-page" className="container-fluid">
            <div className="row mt-2">
                <div className="col d-flex justify-content-center">
                    <div>
                        {/* TODO: Translate this message once fully onboarded to Panther */}
                        {/* https://issues.amazon.com/issues/ITK-12113 */}
                        <h1>Sorry, an unexpected error has occurred.</h1>
                        <b>{`${error?.status}: ${error?.statusText}`}</b>
                        <p>{error?.data}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
