import React, {
	forwardRef,
	useEffect,
	useRef,
	useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { filterProps } from '../utils';
import '@amzn/alchemy-components/dist/Dropdown/index.js';

const Dropdown = forwardRef((props, ref) => {
	const internalRef = useRef(null);
	const {
		children,
		className,
		style,
		onChange,
		onOpen,
		onClose,
		onScroll,
		...rest
	} = props;
	const { attributes, data, events } = filterProps(rest);

	useImperativeHandle(ref, () => internalRef.current);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			Object.assign(internalRef.current, data);
		}
	}, [data]);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			const elRef = internalRef.current;
			const scopedEvents = events;

			elRef.addEventListener('change', onChange);
			elRef.addEventListener('open', onOpen);
			elRef.addEventListener('close', onClose);
			elRef.addEventListener('scroll', onScroll);

			scopedEvents.forEach((ev) => {
				elRef.addEventListener(ev.key, ev.value);
			});

			return () => {
				elRef.removeEventListener('change', onChange);
				elRef.removeEventListener('open', onOpen);
				elRef.removeEventListener('close', onClose);
				elRef.removeEventListener('scroll', onScroll);

				scopedEvents.forEach((ev) => {
					elRef.removeEventListener(ev.key, ev.value);
				});
			};
		}
	}, [data, events]);

	return (
		<alchemy-dropdown
			class={className}
			ref={internalRef}
			{...attributes}
			style={style}
		>
			{children}
		</alchemy-dropdown>
	);
});

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	style: PropTypes.any,
	onChange: PropTypes.func,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	onScroll: PropTypes.func,
};

export default Dropdown;
