import React from 'react';
import { useLocalizationContext } from '@amzn/react-arb-tools';
import { getLocalizationContext } from '../helpers';
import { DEFAULT_LOCALE } from '../constants';
import { Dropdown } from '@amzn/alchemy-components-react';
import * as KatalMetrics from '@amzn/katal-metrics';
import initialMetricsPublisher from '../metrics';

const locales = [
    { label: 'English', value: 'en-US' },
    { label: 'Spanish', value: 'es-ES' },
    { label: 'Italian', value: 'it-IT' },
    { label: 'French', value: 'fr-FR' },
    { label: 'German', value: 'de-DE' },
    { label: 'Japanese', value: 'ja-JP' },
    { label: 'Chinese', value: 'zh-CN' },
    { label: 'Korean', value: 'ko-KR' },
];

const LanguagePicker = () => {
    const { setLocalizationContext } = useLocalizationContext();

    const handleChange = (e: CustomEvent<{ value: string }>) => {
        // @ts-ignore
        const language = e.target.value;

        const localeChangeMetricsPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('LocaleChange');
        localeChangeMetricsPublisher.publish(new KatalMetrics.Metric.String('locale', language || 'NONE'));

        setLocalizationContext(getLocalizationContext(language));
    };

    return (
        <Dropdown
            data-testid="test-language-picker"
            className="mb-1"
            label="Language dropdown"
            options={locales}
            onChange={handleChange}
            value={DEFAULT_LOCALE}
        />
    );
};

export default LanguagePicker;
