import React, {
	forwardRef,
	useEffect,
	useRef,
	useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

import { filterProps } from '../utils';
import '@amzn/alchemy-components/dist/Menu/index.js';

const Menu = forwardRef((props, ref) => {
	const internalRef = useRef(null);
	const { children, className, style, onOpen, onClose, onAction, ...rest } =
		props;
	const { attributes, data, events } = filterProps(rest);

	useImperativeHandle(ref, () => internalRef.current);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			Object.assign(internalRef.current, data);
		}
	}, [data]);

	useEffect(() => {
		if (internalRef && internalRef.current) {
			const elRef = internalRef.current;
			const scopedEvents = events;

			elRef.addEventListener('open', onOpen);
			elRef.addEventListener('close', onClose);
			elRef.addEventListener('action', onAction);

			scopedEvents.forEach((ev) => {
				elRef.addEventListener(ev.key, ev.value);
			});

			return () => {
				elRef.removeEventListener('open', onOpen);
				elRef.removeEventListener('open', onOpen);
				elRef.removeEventListener('close', onClose);
				elRef.removeEventListener('action', onAction);

				scopedEvents.forEach((ev) => {
					elRef.removeEventListener(ev.key, ev.value);
				});
			};
		}
	}, [data, events]);

	return (
		<alchemy-menu
			class={className}
			ref={internalRef}
			style={style}
			{...attributes}
		>
			{children}
		</alchemy-menu>
	);
});

Menu.displayName = 'Menu';

Menu.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	style: PropTypes.any,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
};

export default Menu;
