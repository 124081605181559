import { Link } from 'react-router-dom';

export const WelcomePage = () => {
    return (
        <div id="welcome-page" data-testid="test-welcome-page" className="container-fluid">
            <div className="row">
                <div className="col">
                    <p>Welcome to the Tool Menu</p>
                    <Link to={'demo'}>Click here to see the Demo Page!</Link>
                </div>
            </div>
        </div>
    );
};
