/**
 *
 * @param {*} props
 * Component props
 *
 * filterProps sorts component props based on type
 *  - String props are applied as attributes
 *  - object props are applied with sub notation allowing for
 *    the passing of rich data
 *  - booleans are applied with sub notation as passing as
 *    boolean attributes always presents as true
 */
export const filterProps = (props) => {
	const attributes = {};

	/**
	 * store all events not assigned already by looking into the `onUppercase` pattern
	 */
	const events = [];

	/**
	 * to be applied with sub notation
	 * * more accurately to be merged with ref.current
	 */
	const data = {};

	Object.keys(props).forEach((prop) => {
		const value = props[prop];

		if (prop.substring(0, 2) === 'on' && prop.substring(2, 3).match(/[A-Z]/)) {
			const key = prop.substring(2, props.length).toLowerCase();

			events.push({ key, value });
		} else if (
			typeof value === 'object' ||
			(typeof value === 'boolean' && value === false) ||
			typeof value === 'function'
		) {
			data[prop] = value;
		} else if (prop.replace(/[a-z]/g, '').length) {
			const key = prop.replace(/[A-Z]/g, (l) => `-${l.toLowerCase()}`);

			attributes[key] = value;
		} else {
			attributes[prop] = value;
		}
	});

	return { attributes, data, events };
};
